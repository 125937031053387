import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { db } from '../db';
import ActionPopup from '../components/ActionPopup';
import { FaRobot, FaUser, FaSpinner } from 'react-icons/fa';
import TaskCard from '../components/TaskCard';
import EventCard from '../components/EventCard';
import { v4 as uuidv4 } from 'uuid';

const Chat = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [handledItems, setHandledItems] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [popupActions, setPopupActions] = useState([]);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const messages = await db.messages.toArray();
        setMessages(messages);
      } catch (error) {
        console.error('Error fetching messages:', error);
        setError('Failed to fetch messages.');
      }
    };

    fetchMessages();
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!input.trim()) return;

    const newMessage = {
      role: 'user',
      content: input,
      send_datetime: Date.now(),
    };

    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInput('');
    setIsLoading(true);

    try {
      const response = await axios.post('/api/process-input', {
        input: newMessage.content,
        existingTasks: await db.tasks.toArray(),
        existingCategories: await db.categories.toArray(),
        existingEvents: await db.events.toArray(),
        chatHistory: messages,
      });

      handleServerResponse(response.data);
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleServerResponse = (responseData) => {
    if (responseData.result && responseData.result.actions) {
      const { result, updatedHistory } = responseData;
      const { actions } = result;

      let messageContent = '';
      const items = [];

      actions.forEach((action) => {
        if (action.type === 'chat_response') {
          messageContent = action.data.message;
        } else if (action.type.includes('task') || action.type.includes('event')) {
          items.push(action.data);
        } else {
          console.warn(`Unhandled action type: ${action.type}`);
        }
      });

      if (messageContent || items.length > 0) {
        const assistantMessage = {
          role: 'assistant',
          content: messageContent,
          items,
          send_datetime: Date.now(),
        };

        setMessages(prevMessages => [...prevMessages, assistantMessage]);
        db.messages.add(assistantMessage);
      }

      const nonChatActions = actions.filter(action => !action.type.includes('chat_response'));
      if (nonChatActions.length > 0) {
        setPopupActions(nonChatActions);
        setShowPopup(true);
      }
    } else if (typeof responseData.result === 'string') {
      const assistantMessage = {
        role: 'assistant',
        content: responseData.result,
        send_datetime: Date.now(),
      };

      setMessages(prevMessages => [...prevMessages, assistantMessage]);
      db.messages.add(assistantMessage);
    } else {
      console.error('Unexpected server response format:', responseData);
      setError('Unexpected server response format.');
    }
  };

  const handleItemHandled = async (itemId, action, itemType) => {
    setHandledItems(prev => ({ ...prev, [itemId]: action }));
    
    const actionMessage = {
      role: 'assistant',
      content: `${itemType}: ${action === 'accept' ? 'accepted' : 'rejected'}: ${itemId}`,
      send_datetime: Date.now(),
    };
    setMessages(prevMessages => [...prevMessages, actionMessage]);
    await db.messages.add(actionMessage);
  };

  const renderMessage = (message) => {
    const isUser = message.role === 'user';

    return (
      <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-4`}>
        <div className={`max-w-3/4 p-3 rounded-lg ${isUser ? 'bg-blue-500 text-white' : 'bg-white shadow-md'}`}>
          {typeof message.content === 'object' && message.content.actions ? (
            <>
              <p>{message.content.actions.find(action => action.type === 'chat_response')?.data.message}</p>
              {message.content.actions.map((action, index) => {
                if (action.type.includes('task')) {
                  return (
                    <TaskCard
                      key={index}
                      task={action.data}
                      onItemHandled={(itemId, action) => handleItemHandled(itemId, action, 'Task')}
                      isHandled={handledItems[action.data.id]}
                    />
                  );
                } else if (action.type.includes('event')) {
                  return (
                    <EventCard
                      key={index}
                      event={action.data}
                      onItemHandled={(itemId, action) => handleItemHandled(itemId, action, 'Event')}
                      isHandled={handledItems[action.data.id]}
                    />
                  );
                }
                return null;
              })}
            </>
          ) : (
            <p>{message.content}</p>
          )}
          {message.items && message.items.length > 0 && (
            <ul className="mt-2 space-y-2">
              {message.items.map((item) => {
                if (!item.id) {
                  item.id = uuidv4();
                }
                return (
                  <li key={item.id}>
                    {'due_date' in item ? (
                      <TaskCard
                        task={item}
                        onItemHandled={(itemId, action) => handleItemHandled(itemId, action, 'Task')}
                        isHandled={handledItems[item.id]}
                      />
                    ) : (
                      <EventCard
                        event={item}
                        onItemHandled={(itemId, action) => handleItemHandled(itemId, action, 'Event')}
                        isHandled={handledItems[item.id]}
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 overflow-y-auto p-4" ref={chatContainerRef}>
        {messages.map((message, index) => (
          <div key={index} className="mb-4">
            {message.role === 'user' ? (
              <div className="flex justify-end">
                <div className="bg-blue-500 text-white p-2 rounded-lg max-w-3/4">
                  {renderMessage(message)}
                </div>
                <FaUser className="text-blue-500 ml-2" />
              </div>
            ) : (
              <div className="flex justify-start">
                <FaRobot className="text-gray-500 mr-2" />
                <div className="bg-white p-2 rounded-lg shadow-md max-w-3/4">
                  {renderMessage(message)}
                </div>
              </div>
            )}
          </div>
        ))}
        {isLoading && (
          <div className="flex justify-start">
            <FaRobot className="text-gray-500 mr-2" />
            <div className="bg-white p-2 rounded-lg shadow-md max-w-3/4">
              <FaSpinner className="animate-spin" />
            </div>
          </div>
        )}
        {error && (
          <div className="flex justify-start">
            <FaRobot className="text-gray-500 mr-2" />
            <div className="bg-white p-2 rounded-lg shadow-md max-w-3/4">
              <p className="text-red-500">{error}</p>
            </div>
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit} className="p-4 bg-gray-100">
        <div className="flex">
          <input
            type="text"
            value={input}
            onChange={handleInputChange}
            placeholder="Type your message..."
            className="flex-1 p-2 rounded-l-lg border border-gray-300 focus:outline-none focus:border-blue-500"
          />
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 rounded-r-lg hover:bg-blue-600 focus:outline-none"
          >
            Send
          </button>
        </div>
      </form>
      {showPopup && (
        <ActionPopup
          actions={popupActions}
          onClose={() => setShowPopup(false)}
          onChatResponse={(response) => {
            const assistantMessage = {
              role: 'assistant',
              content: response,
              send_datetime: Date.now(),
            };
            setMessages(prevMessages => [...prevMessages, assistantMessage]);
            db.messages.add(assistantMessage);
          }}
          onComplete={(action, accepted) => {
            const actionMessage = {
              role: 'assistant',
              content: `Action ${accepted ? 'accepted' : 'rejected'}: ${action.type}`,
              send_datetime: Date.now(),
            };
            setMessages(prevMessages => [...prevMessages, actionMessage]);
            db.messages.add(actionMessage);
          }}
        />
      )}
    </div>
  );
};

export default Chat;