import React, { useState, useEffect } from 'react';
import { db } from '../db';
import LogCard from '../components/LogCard';

const Log = () => {
  const [logs, setLogs] = useState([]);
  const [events, setEvents] = useState([]);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    loadLogs();
    loadEvents();
    loadTasks();
  }, []);

  const loadLogs = async () => {
    const storedLogs = await db.logs.toArray();
    setLogs(storedLogs.sort((a, b) => b.timestamp - a.timestamp));
  };

  const loadEvents = async () => {
    const storedEvents = await db.events.toArray();
    setEvents(storedEvents);
  };

  const loadTasks = async () => {
    const storedTasks = await db.tasks.toArray();
    setTasks(storedTasks);
  };

  const handleDeleteLog = async (logId) => {
    try {
      await db.logs.delete(logId);
      setLogs(prevLogs => prevLogs.filter(log => log.id !== logId));
    } catch (error) {
      console.error('Error deleting log:', error);
    }
  };

  return (
    <div className="flex flex-col h-full bg-gray-100">
      <div className="sticky top-0 z-10 bg-white shadow-md">
        <div className="p-4">
          <h2 className="text-2xl font-bold">Action Log</h2>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto">
        <div className="px-4 py-4 pb-20">
          <ul className="space-y-4">
            {logs.map((log) => (
              <li key={log.id}>
                <LogCard log={log} onDelete={handleDeleteLog} events={events} tasks={tasks} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Log;