import React, { createContext, useState, useContext, useEffect } from 'react';
import { db } from '../db';

const CategoryContext = createContext();

export const useCategories = () => useContext(CategoryContext);

export const CategoryProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const loadCategories = async () => {
      const storedCategories = await db.categories.toArray();
      setCategories(storedCategories.map(cat => cat.name));
    };
    loadCategories();
  }, []);

  const addCategory = async (newCategory) => {
    if (!categories.includes(newCategory)) {
      await db.categories.add({ name: newCategory });
      setCategories([...categories, newCategory]);
    }
  };

  return (
    <CategoryContext.Provider value={{ categories, addCategory }}>
      {children}
    </CategoryContext.Provider>
  );
};