import React, { useState, useEffect, useRef } from 'react';
import { FaListUl, FaClock, FaTags, FaTrash, FaCheckCircle, FaSave, FaTimes, FaEdit, FaPlus } from 'react-icons/fa';
import { formatDate } from '../tools/dateUtils';
import { db } from '../db';
import { useCategories } from '../contexts/CategoryContext';
import '../styles/tapEffect.css';

const TaskCard = ({ task, onTaskUpdated, isInChatView = false, onItemHandled, type }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(task.title);
  const [editedDueDate, setEditedDueDate] = useState(task.due_date ? new Date(task.due_date).toISOString().split('T')[0] : '');
  const [editedCategory, setEditedCategory] = useState(task.category || '');
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const categoryContext = useCategories();
  const categories = categoryContext?.categories || [];
  const addCategory = categoryContext?.addCategory;
  const categoryInputRef = useRef(null);

  const handleAccept = async () => {
    try {
      if (type === 'update_task') {
        const confirmUpdate = window.confirm(`Are you sure you want to update the task "${task.new_title}"?`);
        if (!confirmUpdate) return;

        await db.tasks.update(task.id, {
          ...task,
          title: task.new_title,
          updated_date: new Date().toISOString(),
        });
        console.log(`Task updated with ID: ${task.id}`);
      } else {
        // Check for duplicate title (only for new tasks)
        const existingTasks = await db.tasks.where('title').equals(task.title).toArray();
        if (existingTasks.length > 0) {
          const confirmAdd = window.confirm(`A task with the title "${task.title}" already exists. Do you want to add this task anyway?`);
          if (!confirmAdd) return;
        }

        const id = await db.tasks.add({
          ...task,
          created_date: new Date().toISOString(),
        });
        console.log(`Task added with ID: ${id}`);
      }

      onItemHandled(task.id, 'accept', task.title);
      onTaskUpdated();
    } catch (error) {
      console.error('Error handling task:', error);
      alert(`Failed to handle the task: ${error.message}`);
    }
  };

  const handleDoneChange = async (e) => {
    const newDoneState = e.target.checked;
    const confirmMessage = newDoneState ? 'mark this task as done' : 'mark this task as not done';
    if (window.confirm(`Are you sure you want to ${confirmMessage}?`)) {
      const updatedTask = { ...task, done: newDoneState };
      await db.tasks.update(task.id, updatedTask);
      onTaskUpdated();
    } else {
      // If user cancels, revert the checkbox
      e.target.checked = !newDoneState;
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this task?')) {
      setIsDeleting(true);
      await db.tasks.delete(task.id);
      onTaskUpdated();
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveEdit = async () => {
    if (editedTitle.trim() !== '') {
      const updatedTask = { 
        ...task, 
        title: editedTitle.trim(),
        due_date: editedDueDate ? new Date(editedDueDate).toISOString() : null,
        category: editedCategory
      };
      await db.tasks.update(task.id, updatedTask);
      if (editedCategory && addCategory && !categories.includes(editedCategory)) {
        addCategory(editedCategory);
      }
      onTaskUpdated();
      setIsEditing(false);
    }
  };

  const handleCancelEdit = () => {
    setEditedTitle(task.title);
    setEditedDueDate(task.due_date ? new Date(task.due_date).toISOString().split('T')[0] : '');
    setEditedCategory(task.category || '');
    setIsEditing(false);
  };

  const handleCategoryChange = (e) => {
    setEditedCategory(e.target.value);
    setShowCategoryDropdown(true);
  };

  const handleCategorySelect = (category) => {
    setEditedCategory(category);
    setShowCategoryDropdown(false);
  };

  const filteredCategories = categories.filter(cat => 
    cat.toLowerCase().includes(editedCategory.toLowerCase())
  );

  const handleTap = (e) => {
    // Prevent the tap effect from interfering with button clicks
    if (e.target.tagName.toLowerCase() === 'button') {
      e.stopPropagation();
    } else {
      console.log("task card tapped", task);
      // You can add any tap-specific logic here if needed
    }
  };

  if (isInChatView) {
    return (
      <div className="bg-white p-4 rounded-lg shadow-md mb-4" onTouchStart={handleTap}>
        <div className="flex items-start">
          <FaListUl className="text-blue-500 mr-2 mt-1 flex-shrink-0" />
          <div className="flex-grow">
            <p className="font-semibold">
              {task.new_title ? task.new_title : task.title} 
              {task.id && ` id:(${task.id})`}
            </p>
            <p className="text-sm text-gray-600 flex items-center">
              <FaTags className="mr-1" /> {task.category}
            </p>
            {task.due_date && (
              <p className="text-sm text-gray-600 flex items-center">
                <FaClock className="mr-1" /> Due: {formatDate(task.due_date)}
              </p>
            )}
          </div>
        </div>
        <div className="mt-2 flex justify-end">
          {type?.includes('task') && (
            <>
              <button
                onClick={handleAccept}
                className="bg-green-500 hover:bg-green-600 text-white px-2 py-1 rounded text-sm flex items-center mr-2"
              >
                <FaCheckCircle className="mr-1" /> 
                {type === 'update_task' ? 'Accept Update' : 'Accept'}
              </button>
              {type === 'update_task' && (
                <button
                  onClick={() => {
                    try {
                      onItemHandled(task.id, 'create_new', task.title);
                    } catch (error) {
                      console.error('Error creating new task:', error);
                      alert(`Failed to create new task: ${error.message}`);
                    }
                  }}
                  className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded text-sm flex items-center mr-2"
                >
                  <FaPlus className="mr-1" /> Create New
                </button>
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={`bg-white p-4 rounded-lg shadow-md mb-4 transition-opacity duration-300 tap-effect ${isDeleting ? 'opacity-0' : ''}`} onTouchStart={handleTap}>
      <div className="flex items-start">
        <FaListUl className="text-blue-500 mr-2 mt-1 flex-shrink-0" />
        <div className="flex-grow">
          {isEditing ? (
            <div className="flex flex-col">
              <input
                type="text"
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                className="mb-2 p-1 border rounded"
              />
              <input
                type="date"
                value={editedDueDate}
                onChange={(e) => setEditedDueDate(e.target.value)}
                className="mb-2 p-1 border rounded"
              />
              <div className="relative">
                <input
                  ref={categoryInputRef}
                  type="text"
                  value={editedCategory}
                  onChange={handleCategoryChange}
                  onFocus={() => setShowCategoryDropdown(true)}
                  className="mb-2 p-1 border rounded w-full"
                  placeholder="Category"
                />
                {showCategoryDropdown && (
                  <ul className="absolute z-10 bg-white border rounded mt-1 w-full max-h-32 overflow-y-auto">
                    {filteredCategories.map((cat, index) => (
                      <li
                        key={index}
                        className="p-1 hover:bg-gray-200 cursor-pointer"
                        onClick={() => handleCategorySelect(cat)}
                      >
                        {cat}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ) : (
            <>
              <p className="font-semibold">{task.title}</p>
              <p className="text-sm text-gray-600 flex items-center">
                <FaTags className="mr-1" /> {task.category}
              </p>
              {task.due_date && (
                <p className="text-sm text-gray-600 flex items-center">
                  <FaClock className="mr-1" /> Due: {formatDate(task.due_date)}
                </p>
              )}
            </>
          )}
        </div>
      </div>
      {!isEditing && (
        <div className="mt-2 flex justify-between items-center">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={task.done}
              onChange={handleDoneChange}
              className="mr-2"
            />
            Done
          </label>
          <div className="flex space-x-2">
            <FaEdit
              onClick={handleEditClick}
              className="text-blue-500 cursor-pointer hover:text-blue-600"
              title="Edit"
            />
            <FaTrash
              onClick={handleDelete}
              className="text-red-500 cursor-pointer hover:text-red-600"
              title="Delete"
            />
          </div>
        </div>
      )}
      {isEditing && (
        <div className="mt-4 flex justify-end border-t pt-4">
          <button onClick={handleSaveEdit} className="text-green-500 hover:text-green-600 mr-2">
            <FaSave className="inline" title="Save" />
          </button>
          <button onClick={handleCancelEdit} className="text-red-500 hover:text-red-600">
            <FaTimes className="inline" title="Cancel" />
          </button>
        </div>
      )}
    </div>
  );
};

export default TaskCard;