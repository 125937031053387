import React, { useState, useEffect } from 'react';
import { db } from '../db';
import TaskCard from '../components/TaskCard';
import CategoryFilter from '../components/CategoryFilter';
import { FaSearch, FaSort, FaFilter } from 'react-icons/fa';

const Tasks = () => {
  const [tasks, setTasks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [view, setView] = useState('recent');
  const [sortBy, setSortBy] = useState('created_date');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const loadTasks = async () => {
      const storedTasks = await db.tasks.toArray();
      setTasks(storedTasks);
      const uniqueCategories = [...new Set(storedTasks.map(task => task.category))].filter(Boolean);
      setCategories(['All', ...uniqueCategories]);
    };
    loadTasks();
  }, []);

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const filteredTasks = tasks
    .filter(task => selectedCategory === 'All' || task.category === selectedCategory)
    .filter(task => task.title.toLowerCase().includes(searchTerm.toLowerCase()));

  const displayedTasks = view === 'recent' 
    ? filteredTasks.filter(task => !task.done)
    : filteredTasks.filter(task => task.done);

  const sortedTasks = [...displayedTasks].sort((a, b) => {
    let dateA, dateB;
    if (sortBy === 'created_date') {
      dateA = new Date(a.created_date);
      dateB = new Date(b.created_date);
    } else if (sortBy === 'updated_date') {
      dateA = new Date(a.updated_date || a.created_date);
      dateB = new Date(b.updated_date || b.created_date);
    } else if (sortBy === 'due_date') {
      dateA = a.due_date ? new Date(a.due_date) : new Date(8640000000000000);
      dateB = b.due_date ? new Date(b.due_date) : new Date(8640000000000000);
    }
    return dateA - dateB;
  });

  const refreshTasks = async () => {
    const updatedTasks = await db.tasks.toArray();
    setTasks(updatedTasks);
  };

  const handleDeleteAll = async () => {
    if (window.confirm('Are you sure you want to delete all tasks? This action cannot be undone.')) {
      await db.tasks.clear();
      setTasks([]);
      setCategories(['All']);
    }
  };

  return (
    <div className="flex flex-col h-full bg-gray-100">
      <div className="sticky top-0 z-10 bg-white shadow-md">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Tasks</h2>
            <button
              onClick={handleDeleteAll}
              className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
            >
              Delete All
            </button>
          </div>

          <div className="flex items-center space-x-4 mb-4">
            <div className="flex-grow flex items-center bg-white border rounded-lg px-3 py-2">
              <FaSearch className="text-gray-400 mr-2" />
              <input
                type="text"
                placeholder="Search tasks..."
                className="w-full focus:outline-none"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="flex items-center space-x-2">
              <button 
                onClick={() => handleViewChange('recent')}
                className={`px-3 py-2 rounded ${view === 'recent' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
              >
                Recent
              </button>
              <button 
                onClick={() => handleViewChange('done')}
                className={`px-3 py-2 rounded ${view === 'done' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
              >
                Done
              </button>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <div className="flex items-center bg-white border rounded-lg px-3 py-2">
              <FaFilter className="text-gray-400 mr-2" />
              <CategoryFilter 
                categories={categories}
                selectedCategory={selectedCategory}
                onCategoryChange={setSelectedCategory}
              />
            </div>
            <div className="flex items-center bg-white border rounded-lg px-3 py-2">
              <FaSort className="text-gray-400 mr-2" />
              <select 
                value={sortBy} 
                onChange={(e) => setSortBy(e.target.value)} 
                className="bg-transparent focus:outline-none"
              >
                <option value="due_date">Due Date</option>
                <option value="created_date">Created Date</option>
                <option value="updated_date">Updated Date</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto">
        <div className="px-4 py-4">
          <ul className="space-y-4">
            {sortedTasks.length === 0 ? (
              <li>No tasks to display.</li>
            ) : (
              sortedTasks.map((task) => (
                <li key={task.id}>
                  <TaskCard 
                    task={task}
                    onTaskUpdated={refreshTasks}
                    isInChatView={false}
                  />
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Tasks;