import React from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { CategoryProvider } from './contexts/CategoryContext';
import Chat from './pages/Chat';
import Tasks from './pages/Tasks';
import Events from './pages/Events';
import Log from './pages/Log';

function TabBar() {
  const location = useLocation();
  const links = [
    { path: '/', label: 'Chat' },
    { path: '/tasks', label: 'Tasks' },
    { path: '/events', label: 'Events' },
    { path: '/log', label: 'Log' },
  ];

  return (
    <div className="bg-white shadow-lg border-t border-gray-200 h-20 fixed bottom-0 left-0 right-0">
      <div className="flex justify-around h-full">
        {links.map((link) => (
          <Link
            key={link.path}
            to={link.path}
            className={`flex flex-col items-center justify-center w-full h-full transition-colors relative ${
              location.pathname === link.path ? 'text-blue-600' : 'text-gray-600'
            }`}
          >
            <span className={`icon-${link.label.toLowerCase()} mb-1`}></span>
            <span className="text-sm">{link.label}</span>
            {location.pathname === link.path && (
              <div className="absolute bottom-0 left-0 right-0 h-1 bg-blue-600"></div>
            )}
          </Link>
        ))}
      </div>
    </div>
  );
}

function Layout({ children }) {
  return (
    <div className="flex flex-col h-screen">
      <main className="flex-grow overflow-hidden pb-20">
        {children}
      </main>
      <TabBar />
    </div>
  );
}

function App() {
  return (
    <CategoryProvider>
      <Layout>
        <Routes>
          <Route path="/" element={<Chat />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/events" element={<Events />} />
          <Route path="/log" element={<Log />} />
        </Routes>
      </Layout>
    </CategoryProvider>
  );
}

export default App;