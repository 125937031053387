import React from 'react';
import { FaCheckCircle, FaTimesCircle, FaClock, FaTrash } from 'react-icons/fa';
import { formatDate } from '../tools/dateUtils';
import EventCard from './EventCard';
import TaskCard from './TaskCard';

const LogCard = ({ log, onDelete, events, tasks }) => {
    console.log(log);
  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <div className="flex items-start">
        {log.accepted ? 
          <FaCheckCircle className="text-green-500 mr-2 mt-1 flex-shrink-0" /> : 
          <FaTimesCircle className="text-red-500 mr-2 mt-1 flex-shrink-0" />
        }
        <div className="flex-grow">
          <p className="font-semibold text-lg">{log.action}</p>
          <p className="text-sm text-gray-600 mt-1">{log.details}</p>
          <p className="text-sm text-gray-600 flex items-center mt-1">
            <FaClock className="mr-1" /> {formatDate(log.timestamp)}
          </p>
        </div>
        <button
          onClick={() => onDelete(log.id)}
          className="text-red-500 hover:text-red-700 transition-colors"
          title="Delete log"
        >
          <FaTrash />
        </button>
      </div>

      <div className="mt-2">
        <h3 className="font-semibold">Related Events:</h3>
        {events.filter(event => event.logId === log.id).map(event => (
          <EventCard key={event.id} event={event} onAddToCalendar={() => {}} />
        ))}
        
        <h3 className="font-semibold mt-4">Related Tasks:</h3>
        {tasks.filter(task => task.logId === log.id).map(task => (
          <TaskCard key={task.id} task={task} onAccept={() => {}} onReject={() => {}} isHandled={false} />
        ))}
      </div>
    </div>
  );
};

export default LogCard;