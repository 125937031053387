import React from 'react';

const CategoryFilter = ({ categories, selectedCategory, onCategoryChange }) => {
  return (
    <div className="relative">
      <label className="mr-2">Category:</label>
      <select 
        value={selectedCategory} 
        onChange={(e) => onCategoryChange(e.target.value)} 
        className="border rounded p-1"
        style={{ paddingRight: '25px' }}
      >
        {categories.map((category, index) => (
          <option key={index} value={category}>{category}</option>
        ))}
      </select>
      <span className="absolute right-2 top-1/2 transform -translate-y-1/2">&#9662;</span>
    </div>
  );
};

export default CategoryFilter;