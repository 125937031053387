import React, { useState, useEffect, useRef } from 'react';
import { FaCalendarAlt, FaClock, FaTags, FaMapMarkerAlt, FaTrash, FaCheckCircle, FaSave, FaTimes, FaEdit, FaCalendarPlus, FaPlus } from 'react-icons/fa';
import { formatDate } from '../tools/dateUtils';
import { addToCalendar } from '../tools/calendarUtils';
import { db } from '../db';
import { useCategories } from '../contexts/CategoryContext';
import '../styles/tapEffect.css';

const EventCard = ({ event, onEventUpdated, isInChatView = false, onItemHandled, type }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(event.title);
  const [editedStartDate, setEditedStartDate] = useState(event.start_date ? new Date(event.start_date).toISOString().slice(0, 16) : '');
  const [editedEndDate, setEditedEndDate] = useState(event.end_date ? new Date(event.end_date).toISOString().slice(0, 16) : '');
  const [editedCategory, setEditedCategory] = useState(event.category || '');
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const categoryContext = useCategories();
  const categories = categoryContext?.categories || [];
  const addCategory = categoryContext?.addCategory;
  const categoryInputRef = useRef(null);

  useEffect(() => {
    if (showCategoryDropdown && categoryInputRef.current) {
      categoryInputRef.current.focus();
    }
  }, [showCategoryDropdown]);

  const handleDoneChange = async (e) => {
    const newDoneState = e.target.checked;
    const confirmMessage = newDoneState ? 'mark this event as done' : 'mark this event as not done';
    if (window.confirm(`Are you sure you want to ${confirmMessage}?`)) {
      const updatedEvent = { ...event, done: newDoneState };
      await db.events.update(event.id, updatedEvent);
      onEventUpdated();
    } else {
      // If user cancels, revert the checkbox
      e.target.checked = !newDoneState;
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      setIsDeleting(true);
      await db.events.delete(event.id);
      onEventUpdated();
    }
  };

  const handleAccept = async () => {
    try {
      if (type === 'update_event') {
        const confirmUpdate = window.confirm(`Are you sure you want to update the event "${event.new_title || event.title}"?`);
        if (!confirmUpdate) return;

        await db.events.update(event.id, {
          ...event,
          title: event.new_title || event.title,
          updated_date: new Date().toISOString(),
        });
        console.log(`Event updated with ID: ${event.id}`);
      } else {
        // Check for duplicate title (only for new events)
        const existingEvents = await db.events.where('title').equals(event.title).toArray();
        if (existingEvents.length > 0) {
          const confirmAdd = window.confirm(`An event with the title "${event.title}" already exists. Do you want to add this event anyway?`);
          if (!confirmAdd) return;
        }

        const id = await db.events.add({
          ...event,
          created_date: new Date().toISOString(),
        });
        console.log(`Event added with ID: ${id}`);
      }

      onItemHandled(event.id, 'accept', event.title);
      if (onEventUpdated) onEventUpdated();
    } catch (error) {
      console.error('Error handling event:', error);
      alert(`Failed to handle the event: ${error.message}`);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveEdit = async () => {
    if (editedTitle.trim() !== '') {
      const updatedEvent = { 
        ...event, 
        title: editedTitle.trim(),
        start_date: editedStartDate,
        end_date: editedEndDate,
        category: editedCategory
      };
      await db.events.update(event.id, updatedEvent);
      if (editedCategory && addCategory && !categories.includes(editedCategory)) {
        addCategory(editedCategory);
      }
      onEventUpdated();
      setIsEditing(false);
    }
  };

  const handleCancelEdit = () => {
    setEditedTitle(event.title);
    setEditedStartDate(event.start_date ? new Date(event.start_date).toISOString().slice(0, 16) : '');
    setEditedEndDate(event.end_date ? new Date(event.end_date).toISOString().slice(0, 16) : '');
    setEditedCategory(event.category || '');
    setIsEditing(false);
  };

  const handleCategoryChange = (e) => {
    setEditedCategory(e.target.value);
    setShowCategoryDropdown(true);
  };

  const handleCategorySelect = (category) => {
    setEditedCategory(category);
    setShowCategoryDropdown(false);
  };

  const filteredCategories = categories.filter(cat => 
    cat.toLowerCase().includes(editedCategory.toLowerCase())
  );

  const handleTap = (e) => {
    // Prevent the tap effect from interfering with button clicks
    if (e.target.tagName.toLowerCase() === 'button') {
      e.stopPropagation();
    } else {
      console.log("event card tapped", event);
      // You can add any tap-specific logic here if needed
    }
  };

  if (isInChatView) {
    return (
      <div className="bg-white p-4 rounded-lg shadow-md mb-4" onTouchStart={handleTap}>
        <div className="flex items-start">
          <FaCalendarAlt className="text-green-500 mr-2 mt-1 flex-shrink-0" />
          <div className="flex-grow">
            <p className="font-semibold">
              {event.new_title ? event.new_title : event.title}
              {event.id && ` id:(${event.id})`}
            </p>
            {event.category && (
              <p className="text-sm text-gray-600 flex items-center">
                <FaTags className="mr-1" /> {event.category}
              </p>
            )}
            {event.start_date && (
              <p className="text-sm text-gray-600 flex items-center">
                <FaClock className="mr-1" /> Start: {formatDate(event.start_date)}
              </p>
            )}
            {event.end_date && (
              <p className="text-sm text-gray-600 flex items-center">
                <FaClock className="mr-1" /> End: {formatDate(event.end_date)}
              </p>
            )}
            {event.location && (
              <p className="text-sm text-gray-600 flex items-center">
                <FaMapMarkerAlt className="mr-1" /> {event.location}
              </p>
            )}
          </div>
        </div>
        <div className="mt-2 flex justify-end">
          {type?.includes('event') && (
            <>
              <button
                onClick={handleAccept}
                className="bg-green-500 hover:bg-green-600 text-white px-2 py-1 rounded text-sm flex items-center mr-2"
              >
                <FaCheckCircle className="mr-1" /> 
                {type === 'update_event' ? 'Accept Update' : 'Accept'}
              </button>
              {type === 'update_event' && (
                <button
                  onClick={() => {
                    try {
                      onItemHandled(event.id, 'create_new', event.title);
                    } catch (error) {
                      console.error('Error creating new event:', error);
                      alert(`Failed to create new event: ${error.message}`);
                    }
                  }}
                  className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded text-sm flex items-center mr-2"
                >
                  <FaPlus className="mr-1" /> Create New
                </button>
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={`bg-white p-4 rounded-lg shadow-md mb-4 transition-opacity duration-300 ${isDeleting ? 'opacity-0' : ''}`} onTouchStart={handleTap}>
      <div className="flex items-start">
        <FaCalendarAlt className="text-green-500 mr-2 mt-1 flex-shrink-0" />
        <div className="flex-grow">
          {isEditing ? (
            <div className="flex flex-col">
              <input
                type="text"
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                className="mb-2 p-1 border rounded"
              />
              <input
                type="datetime-local"
                value={editedStartDate}
                onChange={(e) => setEditedStartDate(e.target.value)}
                className="mb-2 p-1 border rounded"
              />
              <input
                type="datetime-local"
                value={editedEndDate}
                onChange={(e) => setEditedEndDate(e.target.value)}
                className="mb-2 p-1 border rounded"
              />
              <div className="relative">
                <input
                  ref={categoryInputRef}
                  type="text"
                  value={editedCategory}
                  onChange={handleCategoryChange}
                  onFocus={() => setShowCategoryDropdown(true)}
                  className="mb-2 p-1 border rounded w-full"
                  placeholder="Category"
                />
                {showCategoryDropdown && (
                  <ul className="absolute z-10 bg-white border rounded mt-1 w-full max-h-32 overflow-y-auto">
                    {filteredCategories.map((cat, index) => (
                      <li
                        key={index}
                        className="p-1 hover:bg-gray-200 cursor-pointer"
                        onClick={() => handleCategorySelect(cat)}
                      >
                        {cat}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ) : (
            <>
              <p className="font-semibold cursor-pointer" onClick={handleEditClick}>{event.title}</p>
              {event.category && (
                <p className="text-sm text-gray-600 flex items-center">
                  <FaTags className="mr-1" /> {event.category}
                </p>
              )}
              {event.start_date && (
                <p className="text-sm text-gray-600 flex items-center">
                  <FaClock className="mr-1" /> Start: {formatDate(event.start_date)}
                </p>
              )}
              {event.end_date && (
                <p className="text-sm text-gray-600 flex items-center">
                  <FaClock className="mr-1" /> End: {formatDate(event.end_date)}
                </p>
              )}
              {event.location && (
                <p className="text-sm text-gray-600 flex items-center">
                  <FaMapMarkerAlt className="mr-1" /> {event.location}
                </p>
              )}
            </>
          )}
        </div>
      </div>
      {!isEditing && (
        <div className="mt-2 flex justify-between items-center">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={event.done}
              onChange={handleDoneChange}
              className="mr-2"
            />
            Done
          </label>
          <div className="flex space-x-2">
            <FaEdit
              onClick={handleEditClick}
              className="text-blue-500 cursor-pointer hover:text-blue-600"
              title="Edit"
            />
            <FaTrash
              onClick={handleDelete}
              className="text-red-500 cursor-pointer hover:text-red-600"
              title="Delete"
            />
            <FaCalendarPlus
              onClick={() => addToCalendar(event)}
              className="text-green-500 cursor-pointer hover:text-green-600"
              title="Add to Calendar"
            />
          </div>
        </div>
      )}
      {isEditing && (
        <div className="mt-4 flex justify-end border-t pt-4">
          <button onClick={handleSaveEdit} className="text-green-500 hover:text-green-600 mr-2">
            <FaSave className="inline" title="Save" />
          </button>
          <button onClick={handleCancelEdit} className="text-red-500 hover:text-red-600">
            <FaTimes className="inline" title="Cancel" />
          </button>
        </div>
      )}
    </div>
  );
};

export default EventCard;