import Dexie from 'dexie';

export const db = new Dexie('TaskManagerDB');
db.version(2).stores({
  tasks: '++id, title, category, created_date, updated_date, done',
  events: '++id, title, date, time, duration, created_date, updated_date',
  categories: '++id, name',
  messages: '++id, role, content, send_datetime, items',
  logs: '++id, action, details, accepted, timestamp'
});

// Add a new method to update item status
db.updateItemStatus = async (itemId, status) => {
  const message = await db.messages.where('items').anyOf(itemId).first();
  if (message) {
    const updatedItems = message.items.map(item => 
      item.id === itemId ? { ...item, status } : item
    );
    await db.messages.update(message.id, { items: updatedItems });
  }
};