import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { db } from '../db';
import ActionPopup from '../components/ActionPopup';
import { FaRobot, FaUser, FaSpinner, FaPlus } from 'react-icons/fa';
import TaskCard from '../components/TaskCard';
import EventCard from '../components/EventCard';
import { v4 as uuidv4 } from 'uuid';
import useLongPress from '../tools/useLongPress';

// New Message component
const Message = ({ message, onCopy, onItemAction, handledItems, isTemp, onEventUpdated, onTaskUpdated }) => {
  console.log("message component", message);
  const isUser = message.role === 'user';
  let messageContent = message.content;
  let parsedContent = null;

  if (typeof message.content === 'object') {
    parsedContent = message.content;
  } else {
    try {
      parsedContent = JSON.parse(message.content);
    } catch (error) {
      // If parsing fails, use the original message content
    }
  }

  if (parsedContent && typeof parsedContent === 'object') {
    if (parsedContent.content) {
      messageContent = parsedContent.content;
    }
    if (parsedContent.actions) {
      console.log("found actions", parsedContent.actions);
      const chatResponseAction = parsedContent.actions.find(action => action.type === "chat_response");
      if (chatResponseAction && chatResponseAction.data && chatResponseAction.data.message) {
        messageContent = chatResponseAction.data.message;
      }
    }
  }

  const longPressEvent = useLongPress(
    () => onCopy(messageContent),
    () => {}, // Empty function for onClick
    { delay: 2000 }
  );

  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-4`}>
      <div 
        className={`max-w-3/4 p-3 rounded-lg ${
          isUser 
            ? 'bg-blue-500 text-white' 
            : isTemp 
              ? 'bg-gray-200 text-gray-700' 
              : 'bg-white shadow-md'
        }`}
        {...longPressEvent}
      >
        <div className="flex items-center mb-2">
          {isUser ? <FaUser className="mr-2" /> : <FaRobot className="mr-2" />}
          <span 
            className="text-sm opacity-70 cursor-pointer hover:underline"
            onClick={() => console.log('Message:', message)}
          >
            {new Date(message.send_datetime).toLocaleString()}
          </span>
        </div>
        <p className="mb-2">
          {isTemp ? (
            <>
              {messageContent} <FaSpinner className="inline animate-spin ml-2" />
            </>
          ) : (
            messageContent
          )}
        </p>
        {parsedContent && parsedContent.actions && (
          <ul className="mt-2 space-y-2">
            {parsedContent.actions.map((action, index) => {
              console.log(`Action ${index}:`, action);  // Log each action
              if (action.type.includes('task')) {
                return (
                  <TaskCard
                    key={index}
                    task={action.data}
                    onItemHandled={(id, actionType, title) => onItemAction(action.data, actionType)}
                    isInChatView={true}
                    onTaskUpdated={onTaskUpdated}
                    type={action.type}
                  />
                );
              } else if (action.type.includes('event')) {
                return (
                  <EventCard
                    key={index}
                    event={action.data}
                    onItemHandled={(id, actionType, title) => onItemAction(action.data, actionType)}
                    isInChatView={true}
                    onEventUpdated={onEventUpdated}
                    type={action.type}
                  />
                );
              } else {
                return null;
              }
            })}
          </ul>
        )}
        {message.items && (
          <ul className="mt-2 space-y-2">
            {message.items.map((item, index) => (
              <li key={index}>
                {item.type?.includes('task') ? (
                  <TaskCard
                    task={item}
                    onItemHandled={(id, action, title) => onItemAction(item, action)}
                    isInChatView={true}
                    isHandled={handledItems[item.id]}
                    type={item.type}
                    onTaskUpdated={onTaskUpdated}
                  />
                ) : (
                  <EventCard
                    event={item}
                    onItemHandled={(id, action, title) => onItemAction(item, action)}
                    isInChatView={true}
                    isHandled={handledItems[item.id]}
                    type={item.type}
                    onEventUpdated={onEventUpdated}
                  />
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

const Chat = () => {
  console.log('Chat component rendered');
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [actions, setActions] = useState([]);
  const [handledItems, setHandledItems] = useState({});
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const [loadMoreVisible, setLoadMoreVisible] = useState(false);
  const [copyPopup, setCopyPopup] = useState({ visible: false, x: 0, y: 0, content: '' });

  useEffect(() => {
    const loadMessages = async () => {
      const storedMessages = await db.messages.orderBy('send_datetime').toArray();
      const lastMessages = storedMessages.slice(-30);
      setMessages(lastMessages);
      setLoadMoreVisible(storedMessages.length > 30);

      // Check if there's already a welcome message
      const hasWelcomeMessage = storedMessages.some(msg => 
        msg.role === 'assistant' && msg.content.includes("Welcome!")
      );

      // If there are no messages or no welcome message, add the default AI message
      if (storedMessages.length === 0 || !hasWelcomeMessage) {
        const defaultMessage = {
          id: uuidv4(),
          role: 'assistant',
          content: "Welcome! I'm here to help manage tasks and events. What's new?",
          send_datetime: Date.now()
        };
        
        // Only add to state and database if it doesn't exist
        if (!hasWelcomeMessage) {
          setMessages(prevMessages => [defaultMessage, ...prevMessages]);
          await db.messages.add(defaultMessage);
        }
      }
    };
    loadMessages();
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim() || loading) return;

    const userMessage = { id: uuidv4(), role: 'user', content: input, send_datetime: Date.now() };
    const tempAiMessage = { id: uuidv4(), role: 'assistant', content: 'Processing your request, please wait...', send_datetime: Date.now(), isTemp: true };
    
    setMessages(prevMessages => [...prevMessages, userMessage, tempAiMessage]);
    await db.messages.add(userMessage);
    setInput('');
    setLoading(true);
    setError(null);

    try {
      console.log('Sending request to backend...');
      const apiUrl = process.env.NODE_ENV === 'development' 
        ? 'http://localhost:3992/api/process-input'
        : 'https://tasky.fletrix.hk/api/process-input';

      const response = await axios.post(apiUrl, {
        input,
        existingTasks: await db.tasks.toArray(),
        existingCategories: await db.categories.toArray(),
        existingEvents: await db.events.toArray(),
        chatHistory: messages.slice(-15)
      }, {
        headers: { 'X-API-Secret': '010983983u0nyyyz-92y1-Y_Y_(* ^Y_(T_&' },
        timeout: 30000
      });

      console.log('Received response from backend:', response.data);

      // Remove the temporary message before handling the server response
      setMessages(prevMessages => prevMessages.filter(msg => !msg.isTemp));
      handleServerResponse(response.data);
    } catch (err) {
      console.error('Error in handleSubmit:', err);
      setError(err.message || 'An error occurred while processing your request.');
      // Remove the temporary message in case of error
      setMessages(prevMessages => prevMessages.filter(msg => !msg.isTemp));
    } finally {
      setLoading(false);
      scrollToBottom();
    }
  };

  const adjustDateTimeForHK = (dateTimeString) => {
    const date = new Date(dateTimeString);
    date.setHours(date.getHours() - 8);
    return date.toISOString();
  };

  const handleServerResponse = (responseData) => {
    let messageContent = '';
    const items = [];

    if (responseData.result && responseData.result.actions) {
      for (const action of responseData.result.actions) {
        switch (action.type) {
          case 'chat_response':
            messageContent += `${action.data.message}\n`;
            break;
          case 'task_search':
          case 'event_search':
            handleEnhancedSearch(action.type === 'task_search' ? 'task' : 'event', action.data.query);
            break;
          case 'create_task':
          case 'update_task':
            items.push({
              ...action.data,
              type: action.type,
              new_title: action.data.new_title,
              due_date: action.data.due_date ? adjustDateTimeForHK(action.data.due_date) : null,
            });
            break;
          case 'create_event':
          case 'update_event':
            items.push({
              ...action.data,
              type: action.type,
              new_title: action.data.new_title,
              start_date: adjustDateTimeForHK(action.data.start_date),
              end_date: adjustDateTimeForHK(action.data.end_date),
            });
            break;
          default:
            if (action.type !== 'need_more_info') {
              setActions(prevActions => [...prevActions, action]);
            }
        }
      }
    } else if (responseData.result && typeof responseData.result === 'string') {
      messageContent = responseData.result;
    } else {
      console.error('Unexpected server response format:', responseData);
      setError('An error occurred while processing your request.');
      return;
    }

    if (messageContent || items.length > 0) {
      const assistantMessage = {
        id: uuidv4(),
        role: 'assistant',
        content: messageContent.trim(),
        send_datetime: Date.now(),
        items,
      };
      setMessages(prevMessages => {
        if (!prevMessages.some(msg => msg.id === assistantMessage.id)) {
          return [...prevMessages, assistantMessage];
        }
        return prevMessages;
      });
      db.messages.put(assistantMessage);
    }

    if (responseData.updatedHistory) {
      console.log('Updated history received:', responseData.updatedHistory);
    }
  };

  const handleEnhancedSearch = async (type, query) => {
    const searchQuery = String(query).trim();
    const isIdSearch = isNumeric(searchQuery);
    
    let results;
    if (type === 'task') {
      results = await searchTasks(searchQuery, isIdSearch);
    } else if (type === 'event') {
      results = await searchEvents(searchQuery, isIdSearch);
    } else {
      throw new Error('Invalid search type');
    }

    const searchMessage = {
      role: 'assistant',
      content: `Here are the ${type}s related to "${query}":`,
      send_datetime: Date.now(),
      items: results,
      type: type
    };
    setMessages(prevMessages => [...prevMessages, searchMessage]);
    await db.messages.add(searchMessage);
  };

  function isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  async function searchTasks(query, isIdSearch) {
    if (isIdSearch) {
      const task = await db.tasks.get(parseInt(query, 10));
      return task ? [task] : [];
    } else {
      return await db.tasks
        .filter(task => 
          task.title.toLowerCase().includes(query.toLowerCase()) ||
          (task.description && task.description.toLowerCase().includes(query.toLowerCase()))
        )
        .toArray();
    }
  }

  async function searchEvents(query, isIdSearch) {
    if (isIdSearch) {
      const event = await db.events.get(parseInt(query, 10));
      return event ? [event] : [];
    } else {
      return await db.events
        .filter(event => 
          event.title.toLowerCase().includes(query.toLowerCase()) ||
          (event.description && event.description.toLowerCase().includes(query.toLowerCase()))
        )
        .toArray();
    }
  }

  const handleLoadMore = async () => {
    const allMessages = await db.messages.orderBy('send_datetime').toArray();
    const newMessages = allMessages.slice(0, allMessages.length - messages.length);
    setMessages(prevMessages => [...newMessages, ...prevMessages]);
    setLoadMoreVisible(newMessages.length > 30);
  };

  const clearChat = async () => {
    const confirmClear = window.confirm('Are you sure you want to clear the chat?');
    if (confirmClear) {
      await db.messages.clear();
      setMessages([]);
      setHandledItems({});
    }
  };

  const handleItemAction = async (item, action) => {
    setHandledItems(prev => ({ ...prev, [item.id]: action }));
    
    const itemType = item.start_date ? 'Event' : 'Task';
    let actionMessage;

    if (action === 'create_new') {
      // Create a new item based on the existing one
      const newItem = { ...item, id: uuidv4() };
      delete newItem.id; // Remove the id so a new one will be generated
      
      if (itemType === 'Task') {
        await db.tasks.add(newItem);
      } else {
        await db.events.add(newItem);
      }

      actionMessage = {
        id: uuidv4(),
        role: 'assistant',
        content: `New ${itemType} created: "${item.title}"`,
        send_datetime: Date.now(),
      };
    } else {
      const actionText = item.type?.includes('update') ? 'updated' : 
                         item.type?.includes('create') ? 'created' : 
                         action === 'accept' ? 'accepted' : 'rejected';
      
      actionMessage = {
        id: uuidv4(),
        role: 'assistant',
        content: `${itemType}: "${item.title}" ${actionText}`,
        send_datetime: Date.now(),
      };
    }

    setMessages(prevMessages => {
      if (!prevMessages.some(msg => msg.id === actionMessage.id)) {
        return [...prevMessages, actionMessage];
      }
      return prevMessages;
    });
    await db.messages.put(actionMessage);
  };

  const handleCopy = (content) => {
    navigator.clipboard.writeText(content).then(() => {
      setCopyPopup({ ...copyPopup, visible: true, content: 'Copied!' });
      setTimeout(() => setCopyPopup({ ...copyPopup, visible: false }), 2000);
    });
  };

  const dummyUpdateFunction = () => {
    // This function does nothing, but prevents the "not a function" error
    console.log("Dummy update function called");
  };

  return (
    <div className="flex flex-col h-full bg-gray-100">
      <div className="sticky top-0 z-10 bg-white shadow-md">
        <div className="p-4">
          <h2 className="text-2xl font-bold">Chat</h2>
        </div>
      </div>
      <div className="flex-1 overflow-hidden flex flex-col">
        <div className="flex-1 overflow-y-auto p-4" ref={messagesContainerRef}>
          {loadMoreVisible && (
            <button onClick={handleLoadMore} className="bg-blue-500 text-white p-2 rounded mb-4">Load More</button>
          )}
          {messages.map((message, index) => (
            <Message 
              key={index} 
              message={message} 
              onCopy={handleCopy} 
              onItemAction={handleItemAction}
              handledItems={handledItems}
              isTemp={message.isTemp}
              onEventUpdated={dummyUpdateFunction}
              onTaskUpdated={dummyUpdateFunction}
            />
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 mx-4" role="alert">
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}
      <div className="sticky bottom-0 bg-white border-t border-gray-200 p-4">
        <form onSubmit={handleSubmit} className="flex">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="flex-1 p-2 border rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Type your message..."
            disabled={loading}
          />
          <button
            type="submit"
            className={`${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'} text-white p-2 rounded-r-lg transition-colors`}
            disabled={loading}
          >
            Send
          </button>
        </form>
        <button onClick={clearChat} className="mt-2 bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition-colors w-full">Clear All Chat</button>
      </div>
      {actions.length > 0 && (
        <ActionPopup
          actions={actions}
          onClose={() => setActions([])}
          onInfoNeeded={(request) => handleServerResponse({ actions: [{ type: 'chat_response', data: { message: request } }] })}
          onChatResponse={(message) => handleServerResponse({ actions: [{ type: 'chat_response', data: { message } }] })}
          onComplete={(action, accepted) => {
            const completionMessage = {
              role: 'assistant',
              content: `Action ${accepted ? 'accepted' : 'rejected'}: ${action.type}`,
              send_datetime: Date.now(),
              items: [action.data]
            };
            setMessages(prevMessages => [...prevMessages, completionMessage]);
            db.messages.add(completionMessage);
          }}
        />
      )}
      {copyPopup.visible && (
        <div 
          className="fixed bg-black text-white px-2 py-1 rounded"
          style={{ left: copyPopup.x, top: copyPopup.y }}
        >
          {copyPopup.content}
        </div>
      )}
    </div>
  );
};

export default Chat;